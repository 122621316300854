<template>
    <header>
        <router-link to="/">
            <img src="../assets/image/origins_logo.jpg" alt="Origin's" id="logo-img" />
        </router-link>
    </header>    
</template>

<script>
    export default {
        name: "HeaderComponent"
    }
</script>

<style lang="scss">
    header {
        height: 30vh;
        background-color: #000;
        text-align: center;
    }

    #logo-img {
        height: 90%;
        width: auto;
    }
</style>