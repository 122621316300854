<template>
    <main>
        <MainImage />
        <DescriptionHome />
        <VideoHome />
    </main>
</template>

<script>
    import MainImage from '../components/MainImage.vue'
    import DescriptionHome from '../components/DescriptionHome.vue'
    import VideoHome from '../components/VideoHome.vue'

    export default {
        name: "HomeVue",
        components: {
            MainImage,
            DescriptionHome,
            VideoHome
        },
        title: 'Origin\'s Krew - Danse / Tricks / Arts martiaux - Danse hip-hop/breakdance'
    }
</script>

<style lang="scss">

</style>