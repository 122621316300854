<template>
    <div id="main-img-container">
        <img src="../assets/image/main_image.jpg" alt="" id="main-img" />
        <div id="main-photo-source">
            <a href="https://annevallierphoto.fr" id="main-photo-src-link" target="_blank">
                © Anne Vallier Photo
            </a>
        </div>
    </div>    
</template>

<script>
    export default {
        name: "MainImage"
    }
</script>

<style lang="scss">
    #main-img-container {
        position: relative;
        width: 100%;
        max-height: 100vh;
        text-align: center;
        background-color: #000;
        overflow: hidden;
    }

    #main-img {
        height: auto;
        width: 100%;
        opacity: 0.6;
        animation: zoom-in 25s ease forwards;
    }

    #main-photo-source {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: .5em;;
    }

    #main-photo-src-link {
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        font-size: .9em;;
    }

    @keyframes zoom-in {
        0% {
            transform: scale(1);
            -webkit-transform: scale(1);
        }
        100% {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
        }
    }
</style>