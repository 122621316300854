<template>
    <div id="home-desc-container">
        <div class="home-desc">
            Origin's est une compagnie professionnelle de danse hip hop spécialisée dans le Breakdance. Nous proposons des cours pour tous niveaux, des spectacles sous forme de shows ou de création. Notre structure propose aussi des initiations art martial / tricks.
        </div>
        <div class="home-desc">
            Origin's dispose également de services de production vidéos et photos via Origin's Prod's. 
            <a href="https://originsprods.com" class="home-desc-link" target="_blank">Se rendre sur le site d'Origin's Prod's</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DescriptionHome"
    }
</script>

<style lang="scss">
    #home-desc-container {
        padding: 3em;;
    }

    .home-desc {
        font-size: 1.5em;
        text-align: center;
        padding-bottom: 1.5em;
    }

    .home-desc-link {
        display: block;
        text-decoration: none;
        color: #6a6a6a;
    }

    .home-desc-link:hover {
        columns: #000;
        text-decoration: underline;
    }
</style>