<template>
  <HeaderComponent />
  
  <router-view />

  <FooterComponent />
</template>

<script>
  import HeaderComponent from './components/HeaderComponent.vue'
  import FooterComponent from './components/FooterComponent.vue'

  export default {
    name: "App",
    components: {
      HeaderComponent,
      FooterComponent
    }
  };
</script>

<style lang="scss">
  @font-face {
      font-family: 'Ageo';
      src: url("./assets/fonts/ageo/Ageo-Regular.ttf");
  }

  body {
    margin: 0;
  }

  #app {
      font-family: 'Ageo', Arial, Helvetica, sans-serif;
      color: #000;
      background-color: #FFF;
      font-size: 20px;
      overflow-x: hidden;
  }

  ::-webkit-scrollbar {
      width: 16px;
      background: transparent;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 4px solid transparent;
      background-clip: content-box;
      background-color: #000;
  }

  @media (max-width: 560px) {
        #app {
          font-size: 12px;
        }
    }
</style>
