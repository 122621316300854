<template>
    <div id="home-vide-container">
        <iframe src="https://player.vimeo.com/video/235508984" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
    </div>
</template>

<script>
    export default {
        name: "VideoHome"
    }
</script>

<style lang="scss">
    #home-vide-container {
        position: relative;
        height: calc(480vw / 16);
        overflow: hidden;
        clear: both;
        text-align: center;
        margin-bottom: 3em;
    }

    iframe {
        display: inline-block;
        margin: auto;
        top: 0;
        left: 0;
        aspect-ratio: 16 / 9;
        height: 100%;
        width: auto;
    }

    @media (max-width: 560px) {
        .home-vide-container {
            height: calc(800vw / 16);
        }
    }
</style>