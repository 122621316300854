<template>
    <footer>
        <div class="footer-title-container">
            <h1>
                <router-link to="/" class="footer-title-link">ORIGIN'S KREW</router-link>
            </h1>
        </div>
        <div class="footer-subtitle">Une question ? Contactez-nous</div>
        <div class="footer-mail-link-container">
            <a class="footer-mail-link" href="mailto:contact@originskrew.com">
                <span>contact@originskrew.com</span>
            </a>
        </div>
        <div class="social-link-group">
            <div class="social-link-container">
                <a class="social-link" href="https://www.instagram.com/origins.krew/" target="_blank">
                    <span class="social-link-text">
                        <span data-text="INSTAGRAM" data-text-after="@origins.krew">
                            @origins.krew
                        </span>
                    </span>
                </a>
            </div>
            <div class="social-link-container">
                <a class="social-link" href="https://www.facebook.com/OriginsKrew" target="_blank">
                    <span class="social-link-text">
                        <span data-text="FACEBOOK" data-text-after="OriginsKrew">
                            OriginsKrew
                        </span>
                    </span>
                </a>
            </div>
            <div class="social-link-container">
                <a class="social-link" href="https://vimeo.com/user37918943" target="_blank">
                    <span class="social-link-text">
                        <span data-text="VIDEO" data-text-after="Origin's Krew">
                            Origin's Krew
                        </span>
                    </span>
                </a>
            </div>
        </div>
        <div class="social-link-group">
            <div class="social-link-container">
                <a class="social-link" href="mailto:contact@originskrew.com" target="_blank">
                    <span class="social-link-text">
                        <span data-text="EMAIL" data-text-after="EMAIL">
                            EMAIL
                        </span>
                    </span>
                </a>
            </div>
            <div class="social-link-container fake-container"></div>
            <div class="social-link-container">
                <a class="social-link" href="" target="_blank">
                    <span class="social-link-text">
                        <span data-text="TELEPHONE" data-text-after="TELEPHONE">
                            TELEPHONE
                        </span>
                    </span>
                </a>
            </div>
        </div>
    </footer>
    <div class="copyright-container">
        <div class="copyright-text">
            © 2022 Origin's Krew
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterComponent'
    }
</script>

<style lang="scss">
    ::after, ::before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    footer {
        min-height: 25vh;
        width: 100%;
        background: #000;
        color: #FFF;
    }

    .footer-title-container {
        padding-left: 2em;
    }

    h1 {
        padding-top: 1em;
        padding-bottom: 1em;
        font-size: 3em;
    }

    .footer-title-link {
        color: #FFF;
        text-decoration: none;
    }

    .footer-title-link:hover {
        color: #FFF;
    }

    .footer-subtitle {
        text-align: center;
        font-weight: bold;
        font-size: 1.8em;
        padding-bottom: 1em;
    }

    .footer-mail-link-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 4em;
    }

    .footer-mail-link {
        position: relative;
        padding: 0 0 5px 0;
        color: #FFF;
        text-decoration: none;
        font-size: 1.8em;
    }

    .footer-mail-link::before {
        background: rgba(255,255,255,.3);
    }

    .footer-mail-link::after {
        background: rgba(255,255,255, 1);
        transform: scaleX(0);
        transition: transform .8s cubic-bezier(.19,1,.22,1), -webkit-transform .8s cubic-bezier(.19,1,.22,1), -moz-transform .8s cubic-bezier(.19,1,.22,1), -o-transform .8s cubic-bezier(.19,1,.22,1);
    }

    .footer-mail-link::after, .footer-mail-link::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
    }

    .footer-mail-link:hover span {
        color: lightgray;
    }

    .footer-mail-link:hover::after {
        transform: scaleX(1);
    }

    .social-link-group {
        display: flex;
        flex-wrap: wrap;
    }

    .social-link-container {
        flex: 1 1 33%;
        text-align: center;
        margin-bottom: 2em;
    }

    .social-link {
        text-decoration: none;
        font-weight: bold;
        font-size: 1.1em;
    }

    .social-link-text {
        position: relative;
        display: inline-block;
        overflow: hidden;
        line-height: normal;
        width: 100%;
    }

    .social-link-text span {
        display: inline-block;
        position: relative;
        z-index: 1;
        color: transparent;
        transition: transform 1.2s cubic-bezier(.19,1,.22,1), -webkit-transform 1.2s cubic-bezier(.19,1,.22,1), -moz-transform 1.2s cubic-bezier(.19,1,.22,1), -o-transform 1.2s cubic-bezier(.19,1,.22,1);
    }

    .social-link-text span::before {
        content: attr(data-text);
        top: 0;
        width: 100%;
        transform: skewY(0);
        transform-origin: right bottom;
    }

    .social-link-text span::after {
        content: attr(data-text-after);
        top: 105%;
        transform: skewY(7deg);
        transform-origin: left top;
    }

    .social-link-text span::before, .social-link-text span::after {
        display: block;
        position: absolute;
        color: #606060;
        transition: transform 2s cubic-bezier(.19,1,.22,1), -webkit-transform 2s cubic-bezier(.19,1,.22,1), -moz-transform 2s cubic-bezier(.19,1,.22,1), -o-transform 2s cubic-bezier(.19,1,.22,1);
    }

    .social-link:hover .social-link-text span {
        transform: translateY(-105%);
    }

    .social-link:hover .social-link-text span::after {
        transform: skewY(0);
        color: lightgray;
    }

    .social-link:hover .social-link-text span::before {
        transform: skewY(7deg);
    }

    .copyright-container {
        padding-left: 2em;
        background-color: #000;
        border-top: lightgray 1px solid;
    }

    .copyright-text {
        font-size: 0.7em;
        color: lightgray;
    }

    @media (max-width: 992px) {
        footer {
            min-height: 0;
        }
    }

    @media (max-width: 576px) {
        .social-link-group {
            flex-direction: column;
        }

        .fake-container {
            display: none;
        }
    }
</style>